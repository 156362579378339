import React from 'react';
import add from './../../Assets/Images/add.svg';

const Headboard = () => {
    return (
        <>
            <div className="content-contratos__detalle">
                <div className="row cabecera">
                    <div className="col-4 text-center">Parque</div>
                    <div className="col-4 text-center">Detalle</div>
                    <div className="col-4 text-center">Descripción</div>
                    <div>
                        <button className="btn" type="button" data-toggle="collapse">
                            <img className="icon-action-accordeon" src={add} alt="add" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Headboard;