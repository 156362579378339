import React from 'react';
// Components
import AppRoutes from './routes';
import withClearCache from "./ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
	return <ClearCacheComponent />;
}

function MainApp() {
	return <AppRoutes />
}

export default App;