import React, { useState } from "react";
import { Spinner } from "reactstrap";
import Logo from "../Components/Logo/Logo.jsx";
import { formatRut, validateRut } from "../../service/Utils.js";
import * as Api from "../api";
import Session from "../../service/Session.js";

const Auth = () => {
  const [inputs, setInputs] = useState({ rut: "" });
  const [show, setShow] = useState(false);
  const [disabledButton, setdisabledButton] = useState(false);
  const [rsp, setrsp] = useState();
  const [msg, setmsg] = useState();
  const handleInput = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: formatRut(e.target.value),
    });
  };
  const handleLogIn = async (e) => {
    setShow(true);
    if (inputs.rut === undefined || inputs?.rut === "") {
      setrsp(404);
      setmsg("Debe ingresar un rut");
      setShow(false);
      return false;
    }
    if (!validateRut(inputs.rut)) {
      setrsp(404);
      setmsg("Debe ingresar un rut válido");
      setShow(false);
      return false;
    }
    setdisabledButton(true);
    const data = await Api.SignIn({ rut: inputs.rut });
    const { response, result } = data;
    setShow(false);
    console.info(data);
    if (response === 200) {
      if (parseInt(result.RESULTADO) === 1) {
        Session.setTokenUserPagoRapido(
          result.token,
          inputs.rut,
          result.CORREO,
          result.USUARIO
        );
        window.location.href = "/dashboard";
        //history.push("/dashboard");
      } else {
        setrsp(404);
        setmsg(result.resultError);
        setShow(false);
      }
    } else {
      setrsp(response);
      setmsg(result.resultError);
      setShow(false);
    }
    setdisabledButton(false);
  };
  return (
    <div className="container my-5 py-1" id="auth">
      <div className="row">
        <div className="col-12 offset-md-3 col-md-6">
          <div className="row" id="shadow">
            <Logo />
            <div className="col-12 text-center">
              <p className="title-rut">
                <strong>Ingrese el RUT del Titular</strong>
              </p>
              <div className="row mb-3">
                <div className="col-12 text-center">
                  <input
                    type="text"
                    name="rut"
                    id="rut"
                    value={inputs.rut}
                    onChange={handleInput}
                    placeholder="11.111.111-1"
                    className="form-control"
                  />
                </div>
              </div>
              {parseInt(rsp) === 404 && (
                <div className="row mb-3">
                  <div className="col-12 text-center">
                    <div className="alert alert-danger">{msg}</div>
                  </div>
                </div>
              )}
              <div className="row mb-1">
                <div className="col-12 text-center">
                  <button
                    className="btn-bg-sc bg-purple"
                    onClick={(e) => {
                      handleLogIn(e);
                    }}
                    disabled={disabledButton}
                  >
                    {show ? (
                      <Spinner style={{ width: "2rem", height: "2rem" }} />
                    ) : (
                      <>Ingresar</>
                    )}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <a
                    href="https://www.sacramental.cl/"
                    target="_self"
                    className="btn-bg-sc bg-gray"
                  >
                    Volver a Sacramental
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
